
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AccessDeniedView',

  setup() {
    return {}
  },
})
